import React from 'react';
import PropTypes from 'prop-types';
import styles from './HechoEnMexico.module.css';
import hechoEnMexico from "../../assets/images/app/country/hecho-en-mexico.jpg";


const HechoEnMexico = () => (
  <div className={styles.HechoEnMexico} data-testid="HechoEnMexico">    
    <section className="features11 cid-qLKKv53rzb" id="features11-1q" data-rv-view="496">
      <div className="container">
          <div className="row">          
            <div className="mbr-figure col-3"> 
              <img src={hechoEnMexico} alt="Plasticountry" title="Plasticountry" media-simple="true" className=""/>
            </div>
            <div className="align-left col-9">
              <h2 className="mbr-title pt-2 mbr-fonts-style display-2  ">Manufactura Mexicana</h2>
              <div className="mbr-section-text">
                <p className="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5 text-justify ">La fabricación nuestras líneas de aditivos en México, beneficia al
  transformador  apoyándolo con óptimo tiempo de entrega, eliminación de costos de importación y productos con la más alta calidad en el mercado.</p>
              </div>
              <div className="block-content">
                <div className="card p-3 pr-3">
                  <div className="media">
                    <div className=" align-self-center card-img pb-3">
                      <span className="mbr-iconfont mbri-success" media-simple="true"></span>
                    </div>
                    <div className="media-body">
                      <h4 className="card-title mbr-fonts-style display-5  ">¡Usa productos mexicanos<br /> para apoyar la economía de tu país!</h4>
                    </div>
                  </div>
                  <div className="card-box">
                    <p className="block-text mbr-fonts-style display-7  "></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
  </div>
);

HechoEnMexico.propTypes = {};

HechoEnMexico.defaultProps = {};

export default HechoEnMexico;
