import {React, Component} from "react";
import { Link, Route, Switch } from "react-router-dom";
import { Routes } from "./routes";

import './App.css';
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Empresa from "./components/Empresa/Empresa";
import Agricola from "./components/Agricola/Agricola";
import Calzado from "./components/Calzado/Calzado";
import Automotriz from "./components/Automotriz/Automotriz";
import Biodegradable from "./components/Biodegradable/Biodegradable";
import Masterbatch from "./components/Masterbatch/Masterbatch";
import Nanotecnologia from "./components/Nanotecnologia/Nanotecnologia";
import Laboratorio from "./components/Laboratorio/Laboratorio";
import Footer from "./components/Footer/Footer";
import "./assets/css/new-styles.css";

class App extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  };

  render () {
      return (
        <div className="App">

          <Header />

          <Route exact path={Routes.Home.path}><Home /></Route>
          <Route path={Routes.Empresa.path}><Empresa /></Route>
          <Route path={Routes.Agricola.path}><Agricola /></Route>
          <Route path={Routes.Calzado.path}><Calzado /></Route>
          <Route path={Routes.Automotriz.path}><Automotriz /></Route>
          <Route path={Routes.Biodegradable.path}><Biodegradable /></Route>
          <Route path={Routes.Masterbatch.path}><Masterbatch /></Route>
          <Route path={Routes.Nanotecnologia.path}><Nanotecnologia /></Route>
          <Route path={Routes.Laboratorio.path}><Laboratorio /></Route>
          
          <Footer />
   
        </div>
      );
  }
}

export default App;