import {React, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './Laboratorio.module.css';
import labUseCase from "../../assets/images/app/country/lab-use-case.jpg";
import labMachines from "../../assets/images/app/country/lab-machines.jpg";
import labMachine1 from "../../assets/images/app/country/lab-machine-1.jpg";
import labMachine2 from "../../assets/images/app/country/lab-machine-2.jpg";
import labMachine3 from "../../assets/images/app/country/lab-machine-3.jpg";
import labMachine4 from "../../assets/images/app/country/lab-machine-4.jpg";
import Ilac from '../../assets/images/app/country/ilac.png';
import Pjla from '../../assets/images/app/country/pjla-accreditation.png';

export default function Laboratorio (){

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className={styles.Laboratorio} data-testid="Laboratorio">
      <div class="l-header_container ">
        <section class="header3 cid-qAvYbruiMR mbr-fullscreen" id="header3-5" data-rv-view="493">
          <div class="container">
            <div class="media-container-row">
              <div class="mbr-figure" style={{ width: "100%" }}>
                <img src={labUseCase} alt="Plasticountry" title="" media-simple="true" />
              </div>
              <div class="media-content l-max-widht-media">
                <h1 class="mbr-section-title mbr-white pb-3 mbr-fonts-style display-1"><strong>Laboratorio</strong></h1>
                <h3 class="mbr-section-subtitle align-left mbr-white mbr-light pb-3 mbr-fonts-style display-5 text-justify l-text-block_360px">Plasticountry cuenta con un laboratorio especializado para la realización de distintos estudios como:</h3>
                <div class="mbr-section-text mbr-white pb-3 ">
                  <div class="mbr-text mbr-fonts-style display-7 text-justify l-text-block_360px">
                    <ul>
                      <li class="">Envejecimiento acelerado.</li>
                      <li class="">Evaluación de propiedades mecánicas.</li>
                      <li class="">Calidad de la materia prima y masterbatch fabricado.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="features11 cid-qLLce1npd4" id="features11-2u next" data-rv-view="615">
        <div class="container">
          <div class="col-md-12">
            <div class="media-container-row" style={{ maxWidth: "90vw" }}>
              <div class="mbr-figure" style={{ width: "35%" }}>
                <img src={labMachines} alt="Plasticountry" title="" media-simple="true" />
              </div>
              <div class=" align-left aside-content" style={{ maxWidth: "90vw" }}>
                <h2 class="mbr-title pt-2 mbr-fonts-style display-2">Con la tecnología más avanzada en equipo.</h2>

                <div class="block-content">
                  <div class="card p-3 pr-3" style={{ maxWidth: "90vw" }}>
                    <div class="media">
                      <div class=" align-self-center card-img pb-3">
                        <span class="mbr-iconfont mbri-success" media-simple="true"></span>
                      </div>
                      <div class="media-body">
                        <h4 class="card-title mbr-fonts-style mbr-text display-5 text-justify"><a class="text-warning" href="https://pjlabs.us5.list-manage.com/track/click?u=b3e336c51eabc25dc20cdd433&id=f6495e1c58&e=f42506d474"  target="BLANK">Contamos con la acreditación por parte de PJLA en acuerdo del estandar internacional ISO/IEC 17025:2017</a></h4>
                      </div>
                    </div>
                    <div class="media">
                      <div class=" align-self-center card-img pb-3">
                        <span class="mbr-iconfont mbri-success" media-simple="true"></span>
                      </div>
                      <div class="media-body">
                        <h4 class="card-title mbr-fonts-style mbr-text display-5 text-justify"><a class="text-warning" href="https://pjlabs.us5.list-manage.com/track/click?u=b3e336c51eabc25dc20cdd433&id=f6495e1c58&e=f42506d474" target="BLANK">Nuestras pruebas de laboratorio se realizan bajo los estándares ASTM D3826-18, ASTM D5208-14.</a></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cid-assoc py-5">
        <div className="container">
          <div className="row logos-assoc">
            <div className="col-12">
              <span class="price-value mbr-fonts-style display-5"></span>
              <span class="price-figure mbr-fonts-style display-2">Acreditaciones</span>
              <hr />            
            </div>
            <div className="md-col-6 align-center">
              <img src={Ilac} />
            </div>
            <div className="md-col-6 align-center">
              <img src={Pjla} alt=""/>
            </div>            
          </div>

        </div>
      </section>    


      <section class="mbr-section article content3 cid-qLL91YLUlE" id="content3-2m" data-rv-view="623">
        <div class="container">
          <div class="row">
            <h2 class="align-center pb-3 mbr-fonts-style display-2 col-12 py-5">Evaluaciones disponibles:</h2>

              <div class="mbr-text mbr-fonts-style display-5 l-2_col col-12"><p class="">Mecánicas.</p>
                <p class="">Físicas.</p>
                <p class="">Envejecimiento.</p>
                <p class="">Espectroscópicas.</p>
                <p class="">Mapeos Elementales.</p>
                <p class="">Termodinámicas.</p></div>
            </div>

        </div>
      </section>

      <section class="features2 cid-qMUOzg2SEh" id="features2-s" data-rv-view="39">
        <div class="container">
          <div class="media-container-row">
            <div class="card p-3 col-12 col-md-6 col-lg-3">
              <div class="card-wrapper">
                <div class="card-img">
                  <img src={labMachine1} alt=" Espectrofotómetro de luz" title="Plasticountry" media-simple="true" />
                </div>
                <div class="card-box">
                  <h4 class="card-title pb-3 mbr-fonts-style display-7"> </h4>
                  <p class="mbr-text mbr-fonts-style display-7">
                  Cámara de envejecimiento acelerado por luz UV:<br />Realizado en un ambiente de temperatura controlada, estos ensayos reporducen el daño causado por la luz solar (UV) y rocío en los materiales.<br /> El equipo alterna entre ciclos de luz UV y condensación.<br />ASTM D5208-14
                  </p>
                </div>
              </div>
            </div>
            <div class="card p-3 col-12 col-md-6 col-lg-3">
              <div class="card-wrapper">
                <div class="card-img">
                  <img src={labMachine2} alt=" " title="Plasticountry" media-simple="true" />
                </div>
                <div class="card-box">
                  <h4 class="card-title pb-3 mbr-fonts-style display-7"> </h4>
                  <p class="mbr-text mbr-fonts-style display-7">
                  Resistencia a la tensión y elongación a la ruptura.<br /> Se somete una prueba a una fuerza axial de tracción hasta su ruptura en su máxima resistencia.<br />Se reporta la fuerza máxima a la ruptura, elongación en porcentaje y tensión de la muestra en MPa.<br />Materiales a evaluar:<br />Películas, piezas flexibles y semiflexibles.<br />ASTM D8826-18 / ASTM D882-18                   
                   </p>
                </div>
              </div>
            </div>
            <div class="card p-3 col-12 col-md-6 col-lg-3">
              <div class="card-wrapper">
                <div class="card-img">
                  <img src={labMachine3} alt=" " title="Plasticountry" media-simple="true" />
                </div>
                <div class="card-box"> 
                  <h4 class="card-title pb-3 mbr-fonts-style display-7"></h4>
                  <p class="mbr-text mbr-fonts-style display-7">
                  Espectrofotometría:<br />Evaluación del porcentaje de luz, sombra y la uniformidad de color en función de su transmitancia y/o reflactancia.<br />ASTM D1729 - 16
                    </p>
                </div>
              </div>
            </div>
            <div class="card p-3 col-12 col-md-6 col-lg-3">
              <div class="card-wrapper">
                <div class="card-img">
                  <img src={labMachine4} alt=" " title="Plasticountry" media-simple="true" />
                </div>
                <div class="card-box">
                  <h4 class="card-title pb-3 mbr-fonts-style display-7"> </h4>
                  <p class="mbr-text mbr-fonts-style display-7">
                  Índice de fluidez:<br />Determina la cantidad de material que fluye en un espacio de 10 minutos.<br /> Ayuda a comprobar de manera rápida y efectiva el flujo del polímero a una temperatura particular.<br />ASTM D1238-20
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
};
