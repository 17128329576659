import React from 'react';
import Beneficios from '../Beneficios/Beneficios';
import styles from './Biodegradable.module.css';

const Biodegradable = () => (
  <div className={styles.Biodegradable} data-testid="Biodegradable">
    <div class="l-header_container ">
      <section class="header3 cid-qAvYbruiBIO mbr-fullscreen" id="header3-5" data-rv-view="493">
        <div class="container">
          <div class="media-container-row">
            <div class="mbr-figure" style={{ width: "100%" }}>
              <img src="http://plifeamerican.com/img/db/info-10059-img-S.jpg" alt="Plasticountry" title="" media-simple="true" />
            </div>
            <div class="media-content l-max-widht-media">
              <h1 class="mbr-section-title mbr-white pb-3 mbr-fonts-style display-1">
                <strong>Biodegradable</strong>
              </h1>
              <a href="http://plifeamerican.com">
                <h1 class="mbr-section-subtitle align-left mbr-white mbr-light pb-3 mbr-fonts-style display-2 text-justify l-text-block_360px" style={{color: "rgb(0, 102, 0, 0.7)"}}>
                  P-Life
                </h1>
              </a>
              <div class="mbr-section-text mbr-white pb-3 ">
                <div class="mbr-text mbr-fonts-style display-7 text-justify l-text-block_360px">
                <ul class="l-ul_justify">
                  <li> <p class=""><strong>Tecnología patentada: </strong> Exclusiva de Plasticountry para el Continente Americano.</p></li>
                  <li> <p class=""><strong>Biodegradación: </strong> Hace que los polímeros considerados como no biodegradables, se conviertan en polímeros biodegradables.</p></li>
                  <li> <p class=""><strong>Reintegración: </strong> Acelera el proceso de degradación del plástico que lo reintegra al medio ambiente. </p></li>
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="mbr-section article content11 cid-qLL5DE0DlR" id="content11-2g next" data-rv-view="589">
      <div class="container">
        <div class="media-container-row">
          <div class="mbr-text counter-container col-12 col-md-8 mbr-fonts-style display-7 l-li_justify">
            <h2 class="col-12 py-5 align-center mbr-fonts-style display-2">Aditivo Plástico 100% Biodegradable</h2>
            <ol class="l-ul_justify">
              <li><p class="py-3">Biodegradación natural acelerada.</p></li>
              <li><p class="py-3">Los micro-organismos bio asimilan los compuestos simples.</p></li>
              <li>Gracias a esta reacción biológica, el plástico se reintegra a la tierra formando CO2, agua y biomasa.</li>
              <li><p class="py-3">Está compuesto por ácidos grasos derivados de aceite de palma de coco.</p></li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="mbr-section article content3 cid-qLL91YLUlE" id="content3-2h" data-rv-view="594">
      <div class="container">
        <div class="media-container-row">
          <div class="row col-12 col-md-12">
            <h2 class="col-12 py-5 align-center pb-3 mbr-fonts-style display-2">¿Qué otras ventajas aporta?</h2>
            <div class="col-12 col-md-3 pr-lg-4 mbr-text mbr-fonts-style display-7">
              <h4 class=""><b>Reintegración</b></h4>
              <p class=""><b>Biodegradación</b></p>
              <p class="">Algunos productos fabricados con el aditivo P-Life, han completado su ciclo de Biodegradación hasta en 18 meses.</p>
            </div>
            <div class="col-12 col-md-3 pr-lg-4 mbr-text mbr-fonts-style display-7">
              <h4 class=""><b>Reciclable</b></h4>
              <p class=""><b>Permite ser reprocesado</b></p>
              <p class="">Los productos fabricados con el Aditivo P-Life pueden reprocesarse.</p>
            </div>
            <div class="col-12 col-md-3 pr-lg-4 mbr-text mbr-fonts-style display-7">
              <h4 class=""><b>Alimenticio</b></h4>
              <p class=""><b>Compatible con grado alimenticio</b></p>
              <p class="">Los productos fabricado con el aditivo P-Life pueden estar en contacto con alimentos.</p>            
            </div>
            <div class="col-12 col-md-3 pr-lg-4 mbr-text mbr-fonts-style display-7">
              <h4 class=""><b>Vida útil</b></h4>
              <p class=""><b>Tiempo de vida de anaquel</b></p>
              <p class="">Algunos productos han logrado hasta 1.5 años de vida de anaquel.</p>
            </div>
           </div>
         </div>
       </div>
    </section>
    <Beneficios />

  </div>
);

Biodegradable.propTypes = {};

Biodegradable.defaultProps = {};

export default Biodegradable;
