import React from 'react';
import Beneficios from '../Beneficios/Beneficios';
import styles from './Nanotecnologia.module.css';
import nanoHeader from '../../assets/images/app/country/nano-tech-header.jpg';

const Nanotecnologia = () => (
  <div className={styles.Nanotecnologia} data-testid="Nanotecnologia">
    <div class="l-header_container ">
      <section class="mbr-section info3 cid-qAwQy8RAPA l-header_container_aditivos header1" 
      id="header1-h" data-rv-view="484" 
      style={{zIndex:-100, backgroundPosition: "50% 50%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage:`url(${nanoHeader})`, width: "100%", height: "100vh", minHeight:"500px", maxHeight:"720px", overflow: "hidden", pointerEvents: "none", marginLeft: "0px", marginTop: "16px", visibility: "visible"}}
      >
        <div class="mbr-overlay" style={{opacity: 0.4, backgroundColor:"rgb(35, 35, 35)"}}></div>
        <div class="container pt-5">
          <div class="row justify-content-md-center">
            <div class="media-container-column title col-12 col-md-10">
                <h2 class="align-left mbr-bold mbr-white pb-3 mbr-fonts-style display-1">Nanotecnología</h2>
                <h3 class="mbr-section-subtitle align-left mbr-light mbr-white pb-3 mbr-fonts-style display-5">La química del futuro.</h3>
                <p class="mbr-text align-left mbr-white mbr-fonts-style display-7 text-justify l-text-block_360px">Aditivos funcionales que permiten incrementar propiedades mecánicas, de barrera, vida de anaquel y dispersión entre otras.</p>
            </div>
          </div>
        </div>      
      </section>      
    </div>    
    
    <section class="mbr-section article content11 cid-qLL5DE0DlR" id="content11-2g next" data-rv-view="589">
      <div class="container">
        <div class="media-container-row">
          <div class="mbr-text counter-container col-12 col-md-8 mbr-fonts-style display-7 l-li_justify">
            <h5 class="col-12 py-5 align-center mbr-fonts-style display-2">¿Cuales son sus características?</h5>
            <ol class="l-ul_justify">
              <li> <p class=""><strong>Función: </strong>Aumenta el volumen libre disminuyendo el peso molecular de los entanglements.</p></li>
              <li> <p class="py-3"><strong>Aglomeración vs Alineación: </strong><span style={{fontSize: "1rem"}}> Se igualará el color con menos pigmento.</span></p></li>
              <li> <p class="py-3"><strong>Polímeros compatibles: </strong> Prácticamente pueden combinarse con cualquier polímero.</p></li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="mbr-section article content3 cid-qLL91YLUlE" id="content3-2h" data-rv-view="594">
      <div class="container">
        <div class="media-container-row">      
          <div class="row col-12 col-md-8">
            <h2 class="col-12 py-5 align-center pb-3 mbr-fonts-style display-2">¿Qué otras mejoras aportan?</h2>            
            <div class="col-12 col-md-3 pr-lg-4 mbr-text mbr-fonts-style display-7">
              <p class="">Difusión UV.</p>
              <p class="">Dispersión.</p>
              <p class="">Elongación.</p>
              <p class="">Rigidéz.</p>
            </div>
            <div class="col-12 col-md-3 pr-lg-4 mbr-text mbr-fonts-style display-7">
              <p class="">Barrera al oxígeno.</p>
              <p class="">Hodrofobicidad.</p>
              <p class="">Ópticas.</p>
              <p class="">Estabilidad térmica.</p>
            </div>            
            <div class="col-12 col-md-3 pl-lg-4 mbr-text mbr-fonts-style display-7">
              <p class="">Rendimiento.</p>
              <p class="">Dispersión.</p>
              <p class="">Propiedades mecánicas.</p>
              <p class="">Adhesión.</p>
          </div>
          <div class="col-12 col-md-3 pl-lg-4 mbr-text mbr-fonts-style display-7">
              <p class="">Fluidez.</p>
              <p class="">Oxidación.</p>
              <p class="">Temperatura.</p></div>
          </div>          
        </div>
      </div>
    </section>
    <Beneficios />
  </div>
      );
      
Nanotecnologia.propTypes = {};
      
Nanotecnologia.defaultProps = {};
      
export default Nanotecnologia;
