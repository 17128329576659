export const Routes = {   

    Login: { path: "/login" },
    Home: { path: "/" },
    Empresa: { path: "/empresa/nosotros/grupo+country.html" },
    Agricola: { path: "/areas/agro/linea+agricola.html"},
    Calzado: { path: "/areas/calzado/calzado.html"},
    Automotriz: { path: "/areas/automotriz/automotriz.html"},
    Biodegradable: { path: "/areas/biodegradable/biodegradable.html"},
    Masterbatch: { path: "/areas/master/master+de+color.html"},
    Nanotecnologia: { path: "/areas/nano/nanotecnologia.html"},
    Laboratorio: { path: "/servicios/laboratorio/laboratorio.html"}
};