import {React, useEffect} from 'react';
import Beneficios from '../Beneficios/Beneficios';
import styles from './Masterbatch.module.css';
import masterHeader from "../../assets/images/db/country/master-header.jpg";
import masterColor from "../../assets/images/db/country/master-color.jpg";


export default function Masterbatch (){

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className={styles.Masterbatch} data-testid="Masterbatch">
      <div class="l-header_container ">
        <section class="l-header_container_aditivos header1" 
        id="header1-h" data-rv-view="484" 
        style={{zIndex:-100, backgroundPosition: "50% 50%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage:`url(${masterHeader})`, width: "100%", height: "100vh", minHeight:"500px", maxHeight:"720px", overflow: "hidden", pointerEvents: "none", marginLeft: "0px", marginTop: "16", visibility: "visible"}}
        >
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-md-10 ">
                <h1 class="mbr-section-title align-center mbr-bold pb-3 mbr-fonts-style display-1">Master de color</h1>
                <center>
                  <div class="mbr-text align-center pb-3 mbr-fonts-style display-5 l-text-block_360px align-center py-3">
                    <ul className="list-unstyled">
                      <li>Master de color blanco alta concentración.</li>
                      <li>Master de color negro.</li>
                      <li>Master de color plata.</li>
                    </ul>
                  </div>
                </center>
              </div>
            </div>
          </div>      
        </section>      
      </div>    
      <section class="header3 cid-qAxk8F4Vmv mbr-fullscreen" id="header3-f" data-rv-view="490">
        <div class="container">
          <div class="media-container-row">
            <div class="mbr-figure" style={{width: "180%"}}>
              <img src={masterColor} alt="Plasticountry" title="Plasticountry" media-simple="true" />
            </div>
            <div class="media-content">
              <h1 class="mbr-section-title mbr-white pb-3 mbr-fonts-style display-1"><strong></strong></h1>
              <div class="mbr-section-text mbr-white pb-3 ">
                <h5 class="mbr-fonts-style display-2">Pigmentos</h5>
              </div>
              <div class="mbr-section-text mbr-white pb-3 ">
                <p class="mbr-text mbr-fonts-style display-5 text-justify">
                  Contamos con pigmentos a distintas concentraciones, todos fabricados para satisfacer las más altas exigencias del mercado del plástico en general.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Beneficios />
    </div >
  );
};