import {React, useEffect} from 'react';
import Beneficios from '../Beneficios/Beneficios';
import styles from './Automotriz.module.css';
import autHeader from "../../assets/images/app/country/eva-set-dark-gray-1920.jpg";
import autDetail from "../../assets/images/app/country/eva-light-gray-1920.jpg";

export default function Automotriz (){

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.Automotriz} data-testid="Automotriz">
      <div class="l-header_container ">
        <section class="l-header_container_aditivos header1" 
        id="header1-h" data-rv-view="484" 
        style={{zIndex:-100, backgroundPosition: "50% 50%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage:`url(${autHeader})`, width: "100%", height: "100vh", minHeight:"500px", maxHeight:"720px", overflow: "hidden", pointerEvents: "none", marginLeft: "0px", marginTop: "16", visibility: "visible"}}
        >
          <div class="container">
            <div class="row justify-content-md-left">
              <div class="mbr-black col-md-8 ">
                <h1 class="mbr-section-title align-left mbr-bold pb-3 mbr-fonts-style display-1">Línea Automotriz</h1>
                <h5 class="mbr-section-subtitle align-left mbr-light pb-3 mbr-fonts-style display-2">A la vanguardia</h5>
                  <div class="mbr-text align-left pb-3 mbr-fonts-style display-5 l-text-block_360px py-3">
                    <ul className="list-unstyled">
                      <li>Y apoyando en la producción de materiales clave.</li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>      
        </section>      

        <section class="header3 cid-qAxk8F4Vmv mbr-fullscreen" id="header3-f" data-rv-view="490">
          <div class="container">
            <div class="media-container-row p-5" style={{backgroundColor:"rgba(256,256,256,1)"}}>
              <div class="mbr-figure" style={{width: "180%"}}>
                <img src={autDetail} alt="Plasticountry" title="Plasticountry" media-simple="true" />
              </div>
              <div class="media-content">
                <h1 class="mbr-section-title mbr-white pb-3 mbr-fonts-style display-1"><strong></strong></h1>
                <div class="mbr-section-text mbr-white pb-3 ">
                  <h5 class="mbr-fonts-style display-2">Especializados</h5>
                </div>
                <div class="mbr-section-text mbr-white pb-3 ">
                  <p class="mbr-text mbr-fonts-style display-5 text-justify">
                    Nuestro laboratorio desarrolla formulaciones que facilitarán la producción de insumos plásticos para empaques de repuestos automotrices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> 
      </div>   
      <Beneficios />
    </div>
  );
};
