import {React, useEffect} from 'react';
import styles from './Empresa.module.css';
import AmCham from '../../assets/images/app/country/americanChambersProudMember2020.png';
import Anipac from '../../assets/images/app/country/anipac.png';
import HechoEnMexico from '../HechoEnMexico/HechoEnMexico';
import MapaPresencia from '../MapaPresencia/MapaPresencia';

export default function Empresa (){

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className={styles.Empresa} data-testid="Empresa">
      <section class="cid-qLKDXxBnqE" id="pricing-tables3-1l next" data-rv-view="541">
        <div class="container">
          <div class="row row-eq-height">
          <h1 class="mbr-section-title align-center mbr-bold pb-3 mbr-fonts-style display-1 col-12">Nuestra Empresa</h1>
            <div class="col-12 col-lg-4 col-md-6 my-2">
              <div class="pricing">
                <div class="plan-header">
                  <div class="plan-price">
                    <span class="price-value mbr-fonts-style display-5"> </span>
                    <span class="price-figure mbr-fonts-style display-2">Misión</span>
                    <hr />
                  </div>
                </div>
                <div class="plan-body">
                  <p class="mbr-text mbr-fonts-style display-7 text-justify  ">
                    Ser una empresa que através de sus aditivos de ingeniería, entregue satisfacciones y beneficios a sus clientes, otorgando menores costos para mayores rendimientos, siempre innovando en productos de calidad y cuidando al medio ambiente.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6 my-2">
              <div class="pricing">
                <div class="plan-header">
                  <div class="plan-price">
                    <span class="price-value mbr-fonts-style display-5"></span>
                    <span class="price-figure mbr-fonts-style display-2">Visión</span>
                    <hr />
                  </div>
                </div>
                <div class="plan-body ">
                  <p class="mbr-text mbr-fonts-style display-7 text-justify  ">
                    Ser reconocidos como la compañía mas importante de México en aditivos de ingeniería, colocándonos a la vanguardia en la innovación de productos de calidad al menor costo.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-6 my-2">
              <div class="pricing">
                <div class="plan-header">
                  <div class="plan-price">
                    <span class="price-value mbr-fonts-style display-5"></span>
                    <span class="price-figure mbr-fonts-style display-2">Valores</span>
                    <hr />
                  </div>
                </div>
                <div class="plan-body ">
                  <p class="mbr-text mbr-fonts-style display-7 text-justify  ">En nuestros valores plasmamos nuestras acciones y describimos cómo debemos ser:</p>
                  <ul className="list-unstyled">
                    <li class="text-justify  "><b>Responsabilidad:</b> hacer lo que me corresponde.</li>
                    <li class="text-justify  "><b>Integridad:</b> ser uno mismo.</li>
                    <li class="text-justify  "><b>Colaboración:</b> grupo unido.</li>
                    <li class="text-justify  "><b>Liderazgo:</b> ser siempre los mejores.</li>
                    <li class="text-justify  "><b>Pasión:</b> entregarme al 100%.</li>
                    <li class="text-justify  "><b>Calidad:</b> “para nosotros no tiene limites”.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
        <center>
          <div class=" col-12 col-lg-10 col-md-10 my-10">
            <div class="pricing">
              <div class="plan-header">
                <div class="plan-price">
                  <span class="price-value mbr-fonts-style display-5"></span>
                  <span class="price-figure mbr-fonts-style display-2">Historia</span>
                  <hr />
                </div>
              </div>
              <div class="plan-body">
                <p class="mbr-text mbr-fonts-style display-7 text-justify  ">
                  Plasticountry nace en México en el año 2005 con una propuesta innovadora para la industria del plástico; Con soluciones integrales para lograr ser un proveedor confiable que reúna tres puntos críticos: calidad, productividad y precio para sus  procesos de fabricación, creando sus líneas de productos:
                </p>
                <ul className="list-unstyled">
                  <li>
                  Agrícola
                  </li>
                  <li>
                  Calzado
                  </li>
                  <li>
                  Automotriz
                  </li>
                  <li>
                  Biodegradable
                  </li>
                  <li>
                  Masterbatch
                  </li>      
                  <li>
                  Entre otros.
                  </li>                                                                              
                </ul>
              </div>
            </div>
          </div>
          <br />
        </center>
      </section>
      <HechoEnMexico />
      <MapaPresencia />    

      <section className="cid-assoc py-5">
        <div className="container">
          <div className="row logos-assoc">
            <div className="col-12">
              <span class="price-value mbr-fonts-style display-5"></span>
              <span class="price-figure mbr-fonts-style display-2">Asociaciones</span>
              <hr />            
            </div>
            <div className="md-col-6 align-center">
              <a href="https://www.amcham.org.mx/"><img src={AmCham} alt="American Chambers"/></a>
            </div>
            <div className="md-col-6 align-center">
              <a href="https://anipac.org.mx/"><img src={Anipac} alt="ANIPAC"/></a>
            </div>            
          </div>

        </div>
      </section>          
    </div>
  );
};
