import ReactDOM from 'react-dom';
import styles from './Header.module.css';
import logoNewBrandBg from './../../assets/images/app/country/logoNewBrand_BG_120h.jpg';

import Zoom from "react-reveal/Zoom";
import { Routes } from "../../routes";
import { Link } from "react-router-dom";

export default function Header (){

  const handleClick = () => {
    const nvr = document.getElementById('navbarResponsive');
    if(nvr.classList.contains('show')){
      ReactDOM.findDOMNode(nvr).classList.toggle('show');
    }
  }

  return (
  <div className={styles.Header} data-testid="Header" id="header">
    <Zoom>
    <div className="container">
      <div className="row">
      <div className="container text-center d-none d-lg-block m-5" >
        <Link to={Routes.Home.path} className="">
          <img src={logoNewBrandBg} alt="Plasticountry" title="Plasticountry" media-simple="true" style={{height: "6rem"}} />
        </Link>
      </div>
      <div className="container-fluid text-center d-lg-none m-5">
        <Link to={Routes.Home.path}>
          <img src={logoNewBrandBg} alt="Plasticountry" title="Plastiountry" media-simple="true" style={{ height: "4rem" }} />
        </Link>
      </div>      
      {/*<div className="col-md-9">
        < h1 class="site-heading text-left d-none d-lg-block" >
          <span class="site-heading-upper">GRUPO COUNTRY</span>
          <span class="site-heading-lower text-primary mb-3">Innovando en aditivos plásticos desde 2005</span>
        </h1>
      </div>*/}
      </div>
    </div>
    </Zoom>

    {/*<!-- Navigation -->*/}
    <nav class="navbar navbar-expand-lg navbar-light py-lg-4" id="mainNav">
      <div class="container">        
        <a class="navbar-brand text-uppercase text-expanded font-weight-bold d-lg-none" href="/">Plasticountry</a>
        <Link to={Routes.Home.path} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">        
          <span class="navbar-toggler-icon"></span>        
        </Link>
        <div class="collapse navbar-collapse" id="navbarResponsive">        
          <ul class="navbar-nav mx-auto">
            <li class="nav-item active px-lg-4">
              <Link to={Routes.Home.path} class="nav-link text-uppercase text-expanded">
                Inicio
              </Link>
            </li>
            <li class="nav-item px-lg-4">
              <Link to={Routes.Empresa.path} className="" class="nav-link text-uppercase text-expanded"  onClick={handleClick}>
                Empresa
              </Link>
            </li>
            <li class="dropdown nav-item px-lg-4">
              <a class="dropdown-toggle text-nowrap nav-link text-uppercase text-expanded" data-toggle="dropdown" href="#">Industrias<span class="caret"></span></a>
              <ul class="dropdown-menu text-center">
                <li>
                  <Link to={Routes.Agricola.path} class="nav-link text-uppercase text-expanded" onClick={handleClick}>
                    Agrícola
                  </Link>
                </li>
                <li>
                  <Link to={Routes.Calzado.path} class="nav-link text-uppercase text-expanded" onClick={handleClick}>
                    Calzado
                  </Link>
                </li>
                <li>
                  <Link to={Routes.Automotriz.path} class="nav-link text-uppercase text-expanded" onClick={handleClick}>
                    Automotriz
                  </Link>
                </li>              
              </ul>
            </li>
            <li class="nav-item px-lg-4">
              <Link to={Routes.Biodegradable.path} className="" class="nav-link text-uppercase text-expanded" onClick={handleClick}>
                Biodegradable
              </Link>
            </li>
            <li class="nav-item px-lg-4">
              <Link to={Routes.Masterbatch.path} className="" class="nav-link text-uppercase text-expanded" onClick={handleClick}>
                Masterbatch
              </Link>
            </li>
            <li class="nav-item px-lg-4">
              <Link to={Routes.Nanotecnologia.path} className="" class="nav-link text-uppercase text-expanded" onClick={handleClick}>
                Nanotecnología
              </Link>
            </li>
            <li class="nav-item px-lg-4">
              <Link to={Routes.Laboratorio.path} className="" class="nav-link text-uppercase text-expanded" onClick={handleClick}>
                Laboratorio
              </Link>
            </li>
            <li class="nav-item px-lg-4">
              <a class="nav-link text-uppercase text-expanded" href="#contacto" onClick={handleClick}>Contacto</a>
            </li>                                          
          </ul>
        </div>
      </div>
    </nav>
  </div>
    );
};

Header.propTypes = {};

Header.defaultProps = {};


