import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import styles from './Footer.module.css';
import logoNewBrandBg from './../../assets/images/app/country/logoNewBrand_BG_120h.jpg';
import nailRightNews from "./../../assets/images/app/country/nail_right_news.png";
import { Routes } from "../../routes";
import { Link } from "react-router-dom";

const Footer = () => {

  let [emailForm, setEmailForm] = useState({});

  let requestOptions = {

    method:"POST",
    headers:{"content-Type": "application-json"},
    body:{}
  };

  const handleInputChange = ( event ) => {

    const name = event.target.name;
    const value = event.target.value;
    emailForm[name]=value;
    setEmailForm((emailForm) => emailForm);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    requestOptions.body=JSON.stringify(emailForm);//this has to be here to modify payload/ does not work inside of use effect
    fetch('http://plasticountry.com/lift-REST/index.php/email/0/email', requestOptions)
    .then(response => response.json())
    .then(response => {
      console.table(response);
      if(response.hasOwnProperty('result')){
        emailForm['result'] = response.result;
        setEmailForm((emailForm) => emailForm);
        scroll();        
      }     
    });
  };

  const scroll = () =>{
    let sccs = document.getElementById('sccs');
    if(typeof emailForm.result !== "undefined" && sccs.classList.contains('hidden')){
      ReactDom.findDOMNode(sccs).classList.toggle('hidden');      
    }
    document.getElementById('sccsMsg').scrollIntoView({ behavior: 'smooth'});
  }

  useEffect( () => {       
    console.log('Response: ');
    console.log(emailForm);
  },[]);

  return(
  <div className={styles.Footer} data-testid="Footer">

    <div className={styles.nailLeft}>
      <a href="https://api.whatsapp.com/send?phone=5213315377532&text=Contacto-desde-web-site&app_absent=0" target="_blank" className={styles.whatsapp}>
        <span className="px-2 mbr-iconfont mbr-iconfont-social socicon-whatsapp socicon" media-simple="true"></span>
      </a>
      <a href="https://www.facebook.com/Grupo-Country-100157085549383/?ti=as" target="_blank" className="">
        <span className="px-2 mbr-iconfont mbr-iconfont-social socicon-facebook socicon" media-simple="true"></span>
      </a>
    </div>
    <div className={styles.nailRight}>
        <img src={nailRightNews} />
        <div className={styles.innerNailRight}>
          <h4>Plasticountry</h4>
          <h5>A la vanguardia</h5>
          <p>Contamos con la acreditación:</p>
          <h5>ISO 17025</h5>
          <p>
            <Link to={Routes.Laboratorio.path}>Laboratorio</Link>
          </p>
        </div> 
    </div>


    <section className="mbr-section form1 cid-qLKtmEW3Xc" id="contacto" data-rv-view="527">
      <div className="container" id="form1-a">
        <div className="row justify-content-center">
          <div className="title col-12 col-lg-8">
            <h2 className="mbr-section-title align-center pb-3 mbr-fonts-style display-2 ">Contacto</h2>
            <h3 className="mbr-section-subtitle align-center mbr-light pb-3 mbr-fonts-style display-5 ">Envíenos sus dudas o comentarios, <br></br>en breve estaremos en contacto con usted.</h3>
          </div>
        </div>
      </div>
      <div className="container" id="sccsMsg">
        <div className="row justify-content-center">
          <div className="media-container-column col-lg-8">
            <h3 id="sccs" className="hidden pb-3" >Gracias por enviar sus comentarios.<br />En breve estaremos en contacto con usted.</h3>
            <form className="mbr-form" data-form-title="Plasticountry form" onSubmit={handleSubmit}>

              <div className="row row-sm-offset">
                <div className="col-md-4 multi-horizontal" data-for="name">
                  <div className="form-group">
                    <label className="form-control-label mbr-fonts-style display-7 " for="name-form1-a">Nombre</label>              
                    <input type="text" className="form-control" name="nombre" required="" value={emailForm.nombre} onChange={handleInputChange}/>
                  </div>
                </div>
                <div className="col-md-4 multi-horizontal" data-for="email">
                  <div className="form-group">
                    <label className="form-control-label mbr-fonts-style display-7 " for="email-form1-a">Email</label>
                    <input type="email" className="form-control " name="email" required="" id="email-form1-a" value={emailForm.email} onChange={handleInputChange}/>
                  </div>
                </div>
                <div className="col-md-4 multi-horizontal" data-for="tel">
                  <div className="form-group">
                    <label className="form-control-label mbr-fonts-style display-7 " for="phone-form1-a">Teléfono</label>
                    <input type="tel" className="form-control " name="tel" required="" id="phone-form1-a" value={emailForm.tel} onChange={handleInputChange}/>
                  </div>
                </div>
                <div className="col-md-12 multi-horizontal" data-for="ciudad">
                  <div className="form-group">
                    <label className="form-control-label mbr-fonts-style display-7 " for="ciudad-form1-a">Ciudad</label>
                    <input type="text" className="form-control " name="ciudad" required="" id="ciudad-form1-a" value={emailForm.ciudad} onChange={handleInputChange}/>
                  </div>
                </div>
              </div>
              <div className="form-group" data-for="message">
                <label className="form-control-label mbr-fonts-style display-7 " for="mensaje-form1-a">Mensaje</label>
                <textarea type="text" className="form-control " name="mensaje" rows="7" required="" id="mensaje-form1-a" value={emailForm.mensaje} onChange={handleInputChange}/>
              </div>

              <span className="input-group-btn">
                <input name="enviar" type="submit" className="btn btn-primary btn-form display-7 " />
                </span>
            </form>
          </div>
        </div>
      </div>
    </section>

    
    <div id="footer" className="">
    {/*
      <section className="cid-qAx0s8gG9I" id="social-buttons2-b" data-rv-view="505">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <h2 className="pb-3 mbr-fonts-style display-2 ">
                Síguenos</h2>
              <div className="social-list pl-0 mb-0">
                <a className="" href="https://api.whatsapp.com/send?phone=5213315377532&text=Contacto-desde-web-site&app_absent=0" target="_blank" className="whatsapp">
                  <span className="px-2 mbr-iconfont mbr-iconfont-social socicon-whatsapp socicon" media-simple="true"></span>
                </a>
                <a href="https://www.facebook.com/Grupo-Country-100157085549383/?ti=as" target="_blank" className="">
                  <span className="px-2 mbr-iconfont mbr-iconfont-social socicon-facebook socicon" media-simple="true"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      */}


      <section className="cid-qAx2tGqBFA mbr-reveal" id="footer1-e" data-rv-view="508">
        <div className="container">
          <div className="row content text-white">
            <div className="col-12 col-md-3">
              <div className="">
                <Link to={Routes.Home.path}>
                  <img className="l-logo_footer " src={logoNewBrandBg} alt="Plasticountry" title="Plasticountry" media-simple="true" />
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-3 mbr-fonts-style display-7">
              <h5 className="pb-3 ">
                Ubicación</h5>
              <p className="mbr-text ">Zapopan Jalisco México</p>
              <br></br>
              <p className="">Con servicio en todo el continente Americano.</p>
            </div>
            <div className="col-12 col-md-3 mbr-fonts-style display-7">
              <h5 className="pb-3 ">
                Contacto</h5>
              <p className="mbr-text ">contacto@plasticountry.com<br></br></p>
              <p className="mbr-text "><br></br></p>
              <p className="mbr-text "><br></br></p>
            </div>
            <div className="col-12 col-md-3 mbr-fonts-style display-7">
              <h5 className="pb-3 "> Secciones </h5>
              <p className="mbr-text ">
                <Link to={Routes.Empresa.path}  className="text-warning ">Empresa</Link><br></br>
                <Link to={Routes.Agricola.path}  className="text-warning ">Línea Agrícola</Link><br></br>
                <Link to={Routes.Calzado.path}  className="text-warning ">Línea Calzado</Link><br></br>
                <Link to={Routes.Automotriz.path}  className="text-warning ">Línea Automotriz</Link><br></br>
                <Link to={Routes.Biodegradable.path}  className="text-warning ">Biodegradable</Link><br></br>                                                
                <Link to={Routes.Masterbatch.path}  className="text-secondary ">Masterbatch</Link><br></br>
                <Link to={Routes.Nanotecnologia.path}  className="text-warning ">Nanotecnología</Link><br></br>
                <Link to={Routes.Laboratorio.path}  className="text-warning ">Laboratorio</Link><br></br>           
                <a className="text-warning " href="#contacto">Contacto</a>     
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container " >
          <div className="row justify-content-center ">
            <a href="https://asertivo.mx" target="_BLANK" ><small className="text-muted">Hecho por Asertivo</small></a>
          </div>
        </div>
      </section>      
    </div>
  </div>    
  );
};
    
Footer.propTypes = {};
    
Footer.defaultProps = {};
    
export default Footer;
