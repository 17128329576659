import {React, useEffect}from 'react';
import styles from './Agricola.module.css';
import agroHeader from '../../assets/images/app/country/agro-header.jpg';
import agroBG from '../../assets/images/app/country/agro-bg.jpg';
import agroDetail1 from '../../assets/images/app/country/agro-detail-1.jpg';
import agroDetail2 from '../../assets/images/app/country/agro-detail-2.jpg';
import iconoGray1 from '../../assets/images/app/country/icono_gray_01.png'
import iconoGray2 from '../../assets/images/app/country/icono_gray_02.png'
import iconoGray17 from '../../assets/images/app/country/icono_gray_17.png'
import iconoGray14 from '../../assets/images/app/country/icono_gray_14.png'
import iconoGray4 from '../../assets/images/app/country/icono_gray_04.png'
import iconoGray5 from '../../assets/images/app/country/icono_gray_05.png'

export default function Agricola (){

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.Agricola} data-testid="Agricola">


      <div class="l-header_container ">
        <section class="l-header_container_aditivos header1"
          id="header1-h" data-rv-view="484"
          style={{ zIndex: -100, backgroundPosition: "50% 50%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${agroHeader})`, width: "100%", height: "100vh", minHeight:"500px", maxHeight:"720px", overflow: "hidden", pointerEvents: "none", marginLeft: "0px", marginTop: "16px", visibility: "visible" }}
        >
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="mbr-white col-md-10">
                <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">Línea Agrícola</h1>
                <p class="mbr-text pb-3 mbr-fonts-style display-2">Innovando en plásticos agrícolas desde 2005</p>
                <p class="mbr-text pb-3 mbr-fonts-style display-5">Aditivos especializados que permiten diseñar plásticos con propiedades inteligentes e ideales para cada cultivo.</p>
              </div>
            </div>
          </div>
        </section>
      </div>


      <section class="features11 cid-qMbOQtStvo" id="features11-r" data-rv-view="33">
        <div class="container">
          <div class="col-md-12">
            <div class="media-container-row">
              <div class="mbr-figure" style={{ width: "50%" }}>
                <img src={agroDetail1} alt="Plasticountry" title="" media-simple="true" />
              </div>
              <div class=" align-left aside-content">
                <h5 class="mbr-title pt-2 mbr-fonts-style mbr-light display-2">Aditivos inteligentes</h5>
                <div class="mbr-section-text">
                  <p class="mbr-text mb-5 pt-3 mbr-light mbr-fonts-style display-5 text-justify">
                    Son una nueva generación de productos mexicanos respaldados con tecnología internacional desde su formulación hasta su fabricación, brindado productos eficientes, de calidad y accesibles a toda la industria plástica.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <div style={{ backgroundImage: `url(${agroBG})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>
        <section class="counters1 counters cid-qMbOccZQXI visible full-visible" id="counters1-q" data-rv-view="36" style={{ backgroundColor: "transparent" }}>
          <div class="mbr-overlay" style={{ opacity: 0.9, backgroundColor: "rgb(255, 255, 255)" }}></div>
          <div class="container">
            <h2 class="mbr-section-title pb-3 align-center mbr-fonts-style mbr-light  display-2 ">Aditivos agrícolas</h2>
            <h3 class="mbr-section-subtitle mbr-fonts-style display-5 ">Las diferentes mezclas de polímeros y aditivos permiten diseñar los plásticos con las propiedades ideales para cada cultivo.</h3>

            <div class="container pt-4 mt-2">
              <div class="row">
                <div class="card p-3 align-center col-12 col-md-6">
                  <div class="panel-item p-3" style={{height:"100%"}}>
                    <div class="card-img pb-3">
                      <img src={iconoGray1} style={{ height: "100px", width: "100px" }} alt="Aditivos UV"/>
                    </div>
                    <div class="card-text">
                      <h3 class="pt-3 pb-3 mbr-fonts-style display-5 ">Aditivos UV</h3>
                      <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7 ">Protege a las poliolefinas de los rayos ultravioleta aumentando su vida útil.</h4>
                      <p class="mbr-content-text mbr-fonts-style display-7 text-justify ">
                        Excelente protección anti UV para cualquier tipo de producto plástico, con alta resistencia a químicos y pesticidas para la agricultura.
                      </p>
                    </div>
                  </div>
                </div>


                <div class="card p-3 align-center col-12 col-md-6" style={{height:"100%"}}>
                  <div class="panel-item p-3">
                    <div class="card-img pb-3">
                      <img src={iconoGray2} style={{ height: "100px", width: "100px" }}  alt="Difusor de luz"/>
                    </div>
                    <div class="card-text">
                      <h3 class="pt-3 pb-3 mbr-fonts-style display-5 ">Difusor de Luz</h3>
                      <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7 ">Redirecciona los rayos de luz.</h4>
                      <p class="mbr-content-text mbr-fonts-style display-7 text-justify ">La difusión no debería considerarse como bloqueo o más sombra.<br />
                        En el caso de la difusión, se permite la entrada de la totalidad de luz
                        disponible pero es dispersada a través de una superficie amplia, la
                        difusión debe maximizarse de manera que la luz alcance las hojas
                        inferiores así como las superiores.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section class="features12 cid-qMbKzUYDG9 mbr-parallax-background" id="features12-k"
          data-rv-view="39" style={{ backgroundColor: "transparent", zIndex: "0", position: "relative" }}>
          <div class="mbr-overlay" style={{ opacity: 0.9, backgroundColor: "rgb(255, 255, 255)" }}></div>
          <div class="container">
            <div class="media-container-row pt-5">
              <div class="block-content align-right">
                <div class="card p-3" style={{ opacity: 0.9, backgroundColor: "rgb(255, 255, 255)" }}>
                  <div class="mbr-card-img-title">
                    <div class="card-img pb-3">
                      <img src={iconoGray17} style={{ height: "100px", width: "100px" }}  alt="Sombra"/>
                    </div>
                    <div class="mbr-crt-title"></div>
                  </div>

                  <div class="card-box">
                    <h4 class="p-3 mbr-fonts-style display-5 ">Sombra</h4>
                    <p class="mbr-text mbr-section-text mbr-fonts-style display-7  text-justify ">
                      Con porcentajes de 0 a 100%
                  </p>
                  </div>
                </div>
                <hr />
                <div class="card p-3" style={{ opacity: 0.9, backgroundColor: "rgb(255, 255, 255)" }}>
                  <div class="mbr-card-img-title">
                    <div class="card-img pb-3">
                      <img src={iconoGray14} style={{ height: "100px", width: "100px" }}  alt="Térmico"/>
                    </div>
                    <div class="mbr-crt-title"> </div>
                  </div>
                  <div class="card-box">
                    <h4 class="p-3 mbr-fonts-style display-5 ">Protección química</h4>
                    <p class="mbr-text mbr-section-text mbr-fonts-style display-7 text-justify ">
                      Mayor resistencia al ataque químico, con incrementó en las propiedades mecánicas, mejorando la estabilidad térmica.
                </p>
                  </div>
                </div>
              </div>

              <div class="mbr-figure " style={{ width: "50%" }}>
                <img src={agroDetail2} alt="Plasticountry" title="" media-simple="true" class="" />
              </div>

              <div class="block-content align-left  ">
                <div class="card p-3" style={{ opacity: 0.9, backgroundColor: "rgb(255, 255, 255)" }}>
                  <div class="mbr-card-img-title">
                    <div class="card-img pb-3">
                      <img src={iconoGray4} style={{ height: "100px", width: "100px" }}  alt="Protección química"/>
                    </div>
                    <div class="mbr-crt-title"></div>
                  </div>

                  <div class="card-box">
                    <h4 class="pt-3 mbr-fonts-style display-5 ">Térmico</h4>
                    <p class="mbr-text mbr-section-text mbr-fonts-style display-7 text-justify ">
                      Aditivo formulado para conservación nocturna de la temperatura en invernaderos.
            </p>
                  </div>
                </div>
                <hr />
                <div class="card p-3" style={{ opacity: "0.9", backgroundColor: "rgb(255, 255, 255)" }}>
                  <div class="mbr-card-img-title">
                    <div class="card-img pb-3">
                      <img src={iconoGray5} style={{ height: "100px", width: "100px" }}  alt="Antigoteo"/>
                    </div>
                    <div class="mbr-crt-title">

                    </div>
                  </div>
                  <div class="card-box">
                    <h4 class="p-3 mbr-fonts-style display-5 ">Antigoteo</h4>
                    <p class="mbr-text mbr-section-text mbr-fonts-style display-7 text-justify ">
                      Provoca que las gotas resbalen hacia los extremos del invernadero, aumentando la transmisión de luz.
              </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", overflow: "hidden", pointerEvents: "none", visibility: "hidden", zIndex: "-100" }}>
            <div style={{ backgroundPosition: "50% 50%", backgroundSize: "100%", backgroundRepeat: "no-repeat", backgroundImage: "url('http://plasticountry.com/css/assets/images/pexels-photo-128536-2000x1499.jpg')", position: "fixed", top: "0px", left: "0px", width: "100%", height: "100%", overflow: "hidden", pointerEvents: "none" }}>
            </div>
          </div>
        </section >
      </div>
    </div >
  );
}