import {React, useEffect} from 'react';
import Beneficios from '../Beneficios/Beneficios';
import styles from './Calzado.module.css';
import calzadoHeader from "../../assets/images/app/country/calzado-blanco-header-1920.jpg";
import calzadoDetail from "../../assets/images/app/country/shoes-and-master-black.jpg";


export default function Calzado (){

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className={styles.Calzado} data-testid="Calzado">
      <div class="l-header_container ">
        <section class="l-header_container_aditivos header1" 
        id="header1-h" data-rv-view="484" 
        style={{zIndex:-100, backgroundPosition: "50% 50%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage:`url(${calzadoHeader})`, width: "100%", height: "100vh", minHeight:"500px", maxHeight:"720px", overflow: "hidden", pointerEvents: "none", marginLeft: "0px", marginTop: "16", visibility: "visible"}}
        >
          <div class="container">
            <div class="row justify-content-md-right">
              <div class="col-md-12 align-right">
                <h1 class="mbr-section-title align-right mbr-bold pb-3 mbr-fonts-style display-1">Línea Calzado</h1>
                <h5 class="mbr-section-subtitle align-right mbr-light pb-3 mbr-fonts-style display-2">Siempe innovando</h5>

                  <div class="mbr-text align-right pb-3 mbr-fonts-style display-5 l-text-block_360pxpy-3">
                    <ul className="list-unstyled">
                      <li>Y apoyando en la producción de materiales clave.</li>
                    </ul>
                  </div>

              </div>
            </div>
          </div>      
        </section>      
      </div>    
      <section class="header3 cid-qAxk8F4Vmv mbr-fullscreen" id="header3-f" data-rv-view="490">
        <div class="container">
          <div class="media-container-row p-5" style={{backgroundColor:"rgba(256,256,256,1)"}}>
            <div class="mbr-figure" style={{width: "180%"}}>
              <img src={calzadoDetail} alt="Plasticountry" title="Plasticountry" media-simple="true" />
            </div>
            <div class="media-content">
              <h1 class="mbr-section-title mbr-white pb-3 mbr-fonts-style display-1"><strong></strong></h1>
              <div class="mbr-section-text mbr-white pb-3 ">
                <h5 class="mbr-fonts-style display-2">Especializados</h5>
              </div>
              <div class="mbr-section-text mbr-white pb-3 ">
                <p class="mbr-text mbr-fonts-style display-5 text-justify">
                  Nuestro equipo de investigación trabaja en el desarrollo de formulaciones que facilitarán la producción de insumos plásticos para la industria del calzado.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Beneficios />
    </div>
  );
};
