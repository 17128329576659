import {React, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import styles from './Home.module.css';
import HechoEnMexico from "../HechoEnMexico/HechoEnMexico";
import masterBlancoHome from "../../assets/images/app/country/master-blanco-home.jpg";

export default function Home (){

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className={styles.Home} data-testid="Home">
      <section className="page-section clearfix header-section">
        <div className="container">
          <div className="intro">
            <img className="intro-img img-fluid mb-3 mb-lg-0 rounded" src={masterBlancoHome} alt="" />
            <div className="intro-text left-0 text-center bg-faded p-5 rounded">
              <h2 className="section-heading mb-4">
                <span className="section-heading-upper">Nuestra Especialidad</span>
                <span className="section-heading-lower">Aditivos Plásticos</span>
              </h2>
              <p className="mb-3">Producimos aditivos pl&aacute;sticos que enriquecen las caracter&iacute;sticas de los materiales, mejorando su rendimiento y facilitando su fabricación:</p>
              <p>Contamos con líneas de aditivos para productos de tipo:</p>
              <b>
                <ul className="list-unstyled ">
                  <li><Link className={styles.productList} to={Routes.Agricola.path} >Agrícola</Link></li>
                  <li><Link className={styles.productList} to={Routes.Calzado.path} >Calzado</Link></li>
                  <li><Link className={styles.productList} to={Routes.Automotriz.path} >Automotríz</Link></li>
                  <li><Link className={styles.productList} to={Routes.Biodegradable.path} >Biodegradable</Link></li>
                  <li><Link className={styles.productList} to={Routes.Masterbatch.path} >Masterbatch</Link></li>
                </ul>
              </b>
              <p>Nuestro laboratorio especializado continuamente crea nuevos aditivos para satisfacer las cambiantes necesidades del mercado.</p>
              <div className="intro-button mx-auto">
                <a className="btn btn-primary btn-xl" href="#contacto">Contáctenos</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <HechoEnMexico />
    </div >
  );
};
