import React from 'react';
import PropTypes from 'prop-types';
import styles from './MapaPresencia.module.css';

const MapaPresencia = () => (
  <div className={styles.MapaPresencia} data-testid="MapaPresencia">
    <section className="cid-qLKtmEW3Xc">
      <h2 className="mbr-title display-2 pb-3">Presencia</h2>
      <div className={styles.maps}>        
        <iframe
        src="https://www.google.com/maps/d/embed?mid=1Rvu2mtxsHoC_TdxYxwRBYpGp0mF6jELr&z=2" 
            width="100%"
            height="550"
            frameborder="0"
            scrollwheel="false"                    
        ></iframe>
      </div>
    </section>
  </div>
);
export default MapaPresencia;
