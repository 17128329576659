import React from 'react';
import PropTypes from 'prop-types';
import styles from './Beneficios.module.css';

const Beneficios = () => (
  <div className={styles.Beneficios} data-testid="Beneficios">
    
    <section class="mbr-section content4 cid-qLKPl2lxLe" id="content4-1s" data-rv-view="519">
      <div class="container">
        <div class="media-container-row">
          <div class="title col-12 col-md-8">
            <h5 class="align-center pb-3 mbr-fonts-style display-2">Beneficios al elegir nuestros productos</h5>
          </div>
        </div>
      </div>
    </section>
    <section class="features8 cid-qLKmepcnHa mbr-parallax-background " id="features8-11" data-rv-view="521" 
    style={{backgroundImage: "none", zIndex: 0, position: "relative", backgroundAttachment: "scroll", backgroundSize: "auto"}} data-jarallax-original-styles="background-image: url('http://plasticountry.com/img/db/country/info_17_master_principal_XL.jpg');">
      <div class="container">
        <div class="media-container-row">
          <div class="card  col-12 col-md-6 col-lg-4">
            <div class="card-img">
              <span class="mbr-iconfont mbri-info" media-simple="true"></span>
            </div>
            <div class="card-box align-center">
              <h4 class="card-title mbr-fonts-style display-5 ">Asistencia técnica</h4>
              <p class="mbr-text mbr-fonts-style display-7 text-justify">
                Área de ventas altamente capacitada para ayudar, resolver y orientar a los usuarios de los aditivos y pigmentos.
              </p>
            </div>
          </div>
          <div class="card  col-12 col-md-6 col-lg-4">
            <div class="card-img">
              <span class="mbr-iconfont mbri-user" media-simple="true"></span>
            </div>
            <div class="card-box align-center">
              <h4 class="card-title mbr-fonts-style display-5">Servicio personalizado</h4>
              <p class="mbr-text mbr-fonts-style display-7 text-justify">
                Nuestros clientes son nuestros SOCIOS DE NEGOCIOS, siendo la confianza la parte fundamental de la relación comercial.
              </p>
            </div>
          </div>
          <div class="card  col-12 col-md-6 col-lg-4">
            <div class="card-img">
              <span class="mbr-iconfont mbri-idea" media-simple="true"></span>
            </div>
            <div class="card-box align-center">
              <h4 class="card-title mbr-fonts-style display-5">Desarrollos</h4>
              <p class="mbr-text mbr-fonts-style display-7 text-justify">
                Plasticountry está abierto a lograr desarrollos  personalizados dependiendo las necesidades de cada empresa.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{position: "absolute", top: "0px", left:"0px", width: "100%", height: "100%", overflow: "hidden", pointerEvents: "none", visibility: "hidden", zIndex: -100}} id="jarallax-container-1">
        <div style={{backgroundPosition: "50% 50%", backgroundSize: "100%", backgroundRepeat: "no-repeat", backgroundImage: "url('http://plasticountry.com/img/db/country/info_17_master_principal_XL.jpg')", position: "fixed", top: "0px", left: "0px", width: "1354px", height: "585.323px", overflow: "hidden", pointerEvents: "none", marginLeft: "0px", marginTop: "18.3385px", visibility: "visible", transform: "translate3d(0px, 897.15px, 0px)"}} >
        </div>
      </div>
    </section>
    <section class="features9 cid-qLKQzQa5vC" id="features9-1t" data-rv-view="524">
      <div class="container">
        <div class="row justify-content-center">
          <div class="card p-3 col-12 col-md-6 col-lg-4">
            <div class="media-container-row">
              <div class="card-img pr-2">
                <span class="mbr-iconfont mbri-success" style={{color:"#767676"}} media-simple="true"></span>
              </div>
              <div class="card-box">
                <h4 class="card-title py-3 mbr-fonts-style display-5 text-justify">Calidad</h4>
                <p class="mbr-text mbr-fonts-style display-7 text-justify">
                  Los productos están fabricados con materias primas que cuentan con el respaldo de laboratorios acreditados, los procesos con los que se elaboran tienen personal altamente capacitado.
                </p>
              </div>
            </div>
          </div>
          <div class="card p-3 col-12 col-md-6 col-lg-4">
            <div class="media-container-row">
              <div class="card-img pr-2">
                <span class="mbr-iconfont mbri-cash" style={{color:"#767676"}} media-simple="true"></span>
              </div>
              <div class="card-box">
                <h4 class="card-title py-3 mbr-fonts-style display-5 text-justify">Rentabilidad</h4>
                <p class="mbr-text mbr-fonts-style display-7 text-justify">
                  Los costos de los productos son los más competitivos en el mercado.
                </p>
              </div>
            </div>
          </div>
          <div class="card p-3 col-12 col-md-6 col-lg-4">
            <div class="media-container-row">
              <div class="card-img pr-2">
                <span class="mbr-iconfont mbri-database" style={{color:"#767676"}} media-simple="true"></span>
              </div>
              <div class="card-box">
                <h4 class="card-title py-3 mbr-fonts-style display-5 text-justify">Vanguardia</h4>
                <p class="mbr-text mbr-fonts-style display-7 text-justify">
                  Contamos con la tecnología más avanzada en equipos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

Beneficios.propTypes = {};

Beneficios.defaultProps = {};

export default Beneficios;
